import { render, staticRenderFns } from "./TheForm.vue?vue&type=template&id=24080574&scoped=true&"
import script from "./TheForm.vue?vue&type=script&lang=js&"
export * from "./TheForm.vue?vue&type=script&lang=js&"
import style0 from "./TheForm.vue?vue&type=style&index=0&id=24080574&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24080574",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RichText: require('/opt/build/repo/components/RichComponents/RichText.vue').default,InputStoreSelector: require('/opt/build/repo/components/InputComponents/InputStoreSelector.vue').default,InputTextArea: require('/opt/build/repo/components/InputComponents/InputTextArea.vue').default,InputOptin: require('/opt/build/repo/components/InputComponents/InputOptin.vue').default,ButtonBlob: require('/opt/build/repo/components/ButtonComponents/ButtonBlob.vue').default})
